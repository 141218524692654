import { Typography } from "antd";

function Facts({ facts }) {
	if (!Array.isArray(facts)) return <></>;
	return (
		<div className="py-4">
			{facts &&
				facts.length > 0 &&
				facts.map(fact => {
					const { id, ingredient, info } = fact;
					return (
						<article key={id} className="mb-10">
							<Typography.Title className="bold capitalize mb-1" level={3}>
								{ingredient}
							</Typography.Title>
							<Typography.Text className="normal">{info}</Typography.Text>
						</article>
					);
				})}
		</div>
	);
}

export default Facts;
