// -- libs
import { Space, Button } from "antd";
import React, { useContext } from "react";

// -- context
import CertMarkContext from "context/CertMarkContext";

// -- setup
const brandDefault = {
  name: "",
  website: "",
  contactURL: "",
};

const TaglineBrand = () => {
  const certMark = useContext(CertMarkContext);
  const { catalog: product } = certMark;

  let { customer } = product;
  if (!customer) customer = brandDefault;

  if (!(customer?.display_name || customer?.company_name)) return <></>;

  let webButton = null;
  if (customer.company_website_address) {
    let webURL;
    try {
      webURL = new URL(customer.company_website_address);
      webButton = (
        <Button href={webURL} target="truinsights" shape="round">
          Website
        </Button>
      );
    } catch (e) {
      try {
        webURL = new URL("https://" + customer.company_website_address);
        webButton = (
          <Button href={webURL} target="truinsights" shape="round">
            Website
          </Button>
        );
      } catch (e) {
        webButton = null;
      }
    }
  }

  return (
    <>
      <Space>
        <strong>{customer?.display_name || customer?.company_name}</strong>
        {webButton}
      </Space>
    </>
  );
};

export default TaglineBrand;
