// -- libs
import React, { useContext } from "react";

// -- context
import BatchContext from "context/BatchContext";

// -- components
import IngredientLotOrigins from "@components/IngredientLotOrigins";
import LoadingSkeleton from "@components/LoadingSkeleton";

const TabOrigin = () => {
  const batch = useContext(BatchContext);

  if (batch.loading || batch.loadingDetails) return <LoadingSkeleton />;

  return (
    <>
      <IngredientLotOrigins ingredients={batch.groupedConstituents}></IngredientLotOrigins>
    </>
  );
};

export default TabOrigin;
