// -- libs
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Layout } from 'antd';

// -- context
import { CertMarkProvider } from "context/CertMarkContext";

// -- utils
import axios from "@utils/axios";

// -- components
import OurHeader from '@components/OurHeader';
import OurFooter from '@components/OurFooter';
import ProductDisplay from '@components/ProductDisplay';

// -- setup
const { Content } = Layout;
const initialData = {id:0,tenant_id:0,catalogID:0};


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Info = () => {
  const { code } = useParams();
  const [data, setData] = useState(initialData);
  const [certMark, setCertMark] = useState();

  /**
   * Whenever the code changes look up the info.
   */
  useEffect(() => {
    try {
      const newData = JSON.parse(atob(code));
      setData(newData);
    } catch (e) {
      setData(initialData);
    }
  },[code]);

  /**
   * Whenever we have a new data object, fetch details from the API.
   */
  useEffect(()=>{
    if (!data.id) return;
    axios.get(`/certificationmark/${data.id}`).then((response) => {
      if (!response.data) return;
      if (!response.data.records) return;
      setCertMark(response.data.records);
    })
  }, [data.id]);

  return (
    <CertMarkProvider value={certMark}>
      <Layout>
        <OurHeader />
        <Content>
          <ProductDisplay/>
        </Content>
        <OurFooter />
      </Layout>
    </CertMarkProvider>
  );
}

export default Info;