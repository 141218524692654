import Productinfo from "../shared/productinfo";
import ProductTabs from "../shared/productTabs";

function Productwrapper({ product }) {
	return (
		<div>
			<Productinfo product={product} />
			<ProductTabs product={product} />
		</div>
	);
}

export default Productwrapper;
