// -- libs
import React, { useContext } from "react";
import { Image, Typography, Empty, Card } from "antd";

// -- context
import CertMarkContext from "context/CertMarkContext";
import BatchContext from "context/BatchContext";

// -- components
import PdfView from "@components/PdfView";

const QcAssetView = () => {
  const certMark = useContext(CertMarkContext);
  const batch = useContext(BatchContext);

  const lotCoaLink = batch?.details?.producedlotCoaLink;
  const skuQcLink = certMark?.catalog?.qcSpecLink;
  const foremostCoaLink = batch?.details?.foremostCoaLink;
  

  let headerText;
  if (skuQcLink) headerText = `Batch ${certMark?.catalog?.code} Specification`;
  if (lotCoaLink || foremostCoaLink)
    headerText = `Batch ${batch?.details?.batch_num} Certificate of Analysis`;    
  

  const linkToUse = foremostCoaLink || lotCoaLink || skuQcLink;
  const noLink = !linkToUse;

  if (noLink) return noLinkFallback;

  let content;
  const isPDF = linkToUse.endsWith(".pdf");
  if (isPDF) content = <PdfView url={linkToUse}></PdfView>;
  else content = <Image src={linkToUse} />;

  return (
    <Card title={<Typography.Title level={1}>{headerText}</Typography.Title>}>
      {content}
    </Card>
  );
};

export default QcAssetView;

const noLinkFallback = (
  <Empty description={"Quality Certificate is being processed..."} />
);
