import { Table, Space } from "antd";

function Ingridienttable({ size, data }) {
	if (!Array.isArray(data)) return <></>;
	const { Column, ColumnGroup } = Table;

	return (
		<Table dataSource={data} size={size ?? "default"} key="id">
			<Column title="Name" dataIndex="name" key="name" />
			<ColumnGroup title="Details">
				<Column title="Received" dataIndex="received" index="received" />
				<Column
					title="Lot"
					dataIndex="lot"
					index="lot"
					render={lot => {
						return (
							<Space direction="vertical" size="large">
								{lot.map(item => (
									<span
										style={{
											fontSize: "0.92rem",
										}}>
										{item}
									</span>
								))}
							</Space>
						);
					}}
				/>
				<Column title="Origin" dataIndex="origin" index="origin" />
			</ColumnGroup>
		</Table>
	);
}

export default Ingridienttable;
