import { Table, Typography, Image } from "antd";
import { useMediaQuery } from "hooks/useMediaQuery";
import signature from "assets/demo/images/sign.png";
const columns1 = [
	{
		title: "HEAVY METALS:",
		dataIndex: "title",
	},
	{
		title: "",
		dataIndex: "ppm",
	},
	{
		title: "",
		dataIndex: "icp",
	},
	{
		title: "",
		dataIndex: "status",
	},
];

const columns2 = [
	{
		title: "MICROBIAL ASSAY:",
		dataIndex: "title",
	},
	{
		title: "",
		dataIndex: "ppm",
	},
	{
		title: "",
		dataIndex: "icp",
	},
	{
		title: "",
		dataIndex: "status",
	},
];

function Tests({ Tests }) {
	const isDesktop = useMediaQuery("(min-width:992px)");
	const [data1, data2] = Tests;

	return (
		<div className="py-4">
			<div className="mb-5">
				<Table dataSource={data1} columns={columns1} size={"small"} />
			</div>
			<div>
				<Table dataSource={data2} columns={columns2} size={"small"} />
			</div>
			<div className={`py-5 ${isDesktop && "flex-start"}`}>
				<article className={`${!isDesktop && "mb-5"}`}>
					<Typography.Title level-={6} className="extrabold mb-1 text-md">
						Quality Checked By:
					</Typography.Title>
					<p className="normal">Dave Martin, QA specialist</p>
				</article>
				<Image src={signature} alt="signature" preview={false} />
			</div>
		</div>
	);
}

export default Tests;
