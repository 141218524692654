// -- libs
import React, { useContext, useEffect, useState } from "react";
import { Select, Form } from "antd";

// -- context
import BatchContext from "context/BatchContext";

// -- Setup
const { Option } = Select;

/**
 * <BatchSelector .../>
 * @returns {JSX.Element}
 * @constructor
 */
export const BatchSelector = ({ sku }) => {
  const batch = useContext(BatchContext);
  const [options, setOptions] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState();

  /**
   * SKU changed? get new batch list.
   */
  useEffect(() => {
    if ("getSummaryList" in batch)
      batch.getSummaryList({ produced_cat_num: sku });
  }, [sku]);

  /**
   * If batch loading changes do this, but only really do something if it is
   * done loading.
   */
  useEffect(() => {
    if (!batch.list.length) return;
    let firstValue;
    setOptions(
      batch.list.map((data) => {
        if (!data.batch_num) return null;
        if (!firstValue) {
          firstValue = data.id;
          setSelectedBatch(firstValue);
          onChange(firstValue);
        }
        return (
          <Option key={data.id} value={data.id} select>
            {data.batch_num}
          </Option>
        );
      })
    );
  }, [batch.list]);

  /**
   * When new batch number is selected...
   * @param id
   */
  const onChange = (id) => {
    if (!id) return;
    batch.getDetails({ id });
  };

  if (!options.length) return <span />;

  return (
    <Form.Item>
        <Select
          loading={batch.loading}
          defaultValue={selectedBatch}
          placeholder="Select a batch"
          onChange={onChange}
        >
          {options}
        </Select>
    </Form.Item>
  );
};
