import { Layout } from "antd";
import OurHeader from "@components/OurHeader";
import OurFooter from "@components/OurFooter";

function Genlayout({ children }) {
	const { Content } = Layout;
	return (
		<Layout>
			<OurHeader />
			<div className="site-layout-content">
				<Content>{children}</Content>
			</div>
			<OurFooter />
		</Layout>
	);
}
export default Genlayout;
