// -- libs
import React, { useContext } from "react";
import { Divider, Row, Col } from 'antd';
import parse from "html-react-parser";

// -- context
import CertMarkContext from 'context/CertMarkContext';

const ProductHeader = () => {
  const certMark = useContext(CertMarkContext)
  const { catalog: product = {} } = certMark;

  return (
    <>
      <Row>
        <Col span={24}>
          <h2>{product.label}</h2>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Divider />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {parse(product.description||'We are working on updating the' +
            ' description for this product.')}
        </Col>
      </Row>
    </>
  );
};

export default ProductHeader;