// -- libs
import { useParams } from 'react-router-dom';

// components
import Productwrapper from "@components/demo/wrapper/productwrapper";
import Genlayout from "@components/demo/layout/genlayout";

// data
import demoData from "data/demo_products";


function Demo() {
	const { sku } = useParams();
	return (
		<Genlayout>
			<Productwrapper product={demoData[sku]} />
		</Genlayout>
	);
}

export default Demo;
