// -- libs
import React, { useState, useMemo } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Card, Typography } from "antd";

// setup
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// from react-pdf recipes:
// https://github.com/wojtekmaj/react-pdf/wiki/Recipes
// with minimal tweaks

const PdfView = ({ url, headerText, cardWrap }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const fileObj = useMemo(() => ({ url }), [url]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) =>
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  const previousPage = () => changePage(-1);
  const nextPage = () => changePage(1);

  const hasMultiPages = numPages > 1;

  const pagingDisp = (
    <div>
      <p>
        Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
      </p>
      <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
        Previous
      </button>
      <button
        type="button"
        disabled={pageNumber >= numPages}
        onClick={nextPage}
      >
        Next
      </button>
    </div>
  );

  const pdfDisp = (
    <>
      {hasMultiPages && pagingDisp}
      <Document
        file={fileObj}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.warn}
      >
        <Page height={1200} pageNumber={pageNumber} />
      </Document>
    </>
  );

  if (!cardWrap) return pdfDisp;
  return (
    <Card title={<Typography.Title level={1}>{headerText}</Typography.Title>}>
      {pdfDisp}
    </Card>
  );
};

export default PdfView;
