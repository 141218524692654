import { v4 as uuid } from "uuid";
//

// the product info
const solution = {
	map_url: "/demo/assets/images/map_CAGANJTXUT_CNDE.png",
	product_name: "Thyroid Max 120 count",
	batch_no: "0023044031",
	expiry_date: " 01/13/2024",
	product_description:
		"Thyroid, Adrenal, Pituitary, Hypothalamus support with an emphasis on the thyroid. ",
	ingredients: [
		{
			id: uuid(),
			name: "L-Tyrosine",
			received: "08/13/2021",
			lot: ["ID#7578-280721", "ID#6691-200621"],
			origin: "Demark",
			code: "DE",
		},
		{
			id: uuid(),
			name: "Adrenal [Desiccated Beef Suprarenal Substance]",
			received: "10/17/2021",
			lot: ["ID# 9472-111021", "ID#7269-280621"],
			origin: "Nebraska, USA",
			code: "US",
		},
		{
			id: uuid(),
			name: "Kelp [Organic] [Ascophyllum Nodosum]",
			received: "08/05/2021",
			lot: ["ID#7176-240621"],
			origin: "Thailand",
			code: "TH",
		},
		{
			id: uuid(),
			name: "Manganese Citrate 10%",
			received: "11/17/2021",
			lot: ["ID#7214-250621"],
			origin: "Argentina",
			code: "AR",
		},
		{
			id: uuid(),
			name: "Anterior Pituitary Substance [Desiccated Beef]",
			received: "09/23/2021",
			lot: ["ID# 9455-111021", "ID#6450-130421"],
			origin: "Kansas, USA",
			code: "US",
		},
		{
			id: uuid(),
			name: "Spleen Substance [Desiccated Beef]",
			received: "10/08/2021",
			lot: ["ID#6668-190621"],
			origin: "Texas, USA",
			code: "US",
		},
		{
			id: uuid(),
			name: "Thyroid Substance Powder [Beef]",
			received: "10/08/2021",
			lot: ["ID#7045-230621"],
			origin: "Texas, USA",
			code: "US",
		},
		{
			id: uuid(),
			name: "175cc White HDPE Bottle [38/400]",
			received: "09/06/2021",
			lot: ["ID#7494-260721"],
			origin: "China",
			code: "CH",
		},
		{
			id: uuid(),
			name: "38mm White, Smooth Top Pressure Sensitive Cap 38/400",
			received: "11/03/2021",
			lot: ["ID#7531-260721"],
			origin: "China",
			code: "CH",
		},
		{
			id: uuid(),
			name: "66.0 x 020mm Printed Shrink Band [38mm]",
			received: "11/01/2021",
			lot: ["ID# 10109-101121"],
			origin: "China",
			code: "CH",
		},
	],
	product_info: [
		{
			id: uuid(),
			info: "Manufacturer",
			details: "lorem industries",
		},
		{
			id: uuid(),
			info: "Website",
			details: "https://naturalsolutionswholesale.com/",
		},
		{
			id: uuid(),
			info: "Phone Number",
			details: "+11 2346778",
		},
		{
			id: uuid(),
			info: "Batch Created",
			details: "1/2/2020",
		},
		{
			id: uuid(),
			info: "Quality Tested",
			details: "2/2/2020",
		},
		{
			id: uuid(),
			info: "Verified",
			details: "2/3/20202",
		},
		{
			id: uuid(),
			info: "Recall Notice",
			details: "None",
		},
	],
	filelist: [
		{
			id: uuid(),
			object_url: "/demo/assets/images/natural_thyroid_max_120_main.png",
		},
		{
			id: uuid(),
			object_url: "/demo/assets/images/natural_thyroid_max_120_back.png",
		},
		{
			id: uuid(),
			object_url: "/demo/assets/images/natural_thyroid_max_120_side.png",
		},
	],
	scientific_facts: [
		{
			id: uuid(),
			ingredient: "L-Tyrosine",
			info: "L-tyrosine is an amino acid (a building block of proteins). It may aid in alertness, attention and focus. It may also improve mental performance in stressful situations, help those with Phenylketonuria (a rare genetic enzyme condition) and depression.",
		},
		{
			id: uuid(),
			ingredient: "Adrenal [Desiccated Beef Suprarenal Substance]",
			info: "Adrenal Extracts, whose active ingredient is hydrocortison (a type of steroid), may boost energy and memory and may play a role in providing natural stress relief.",
		},
		{
			id: uuid(),
			ingredient: "Kelp [Organic] [Ascophyllum Nodosum]",
			info: "Kelp is a type of large brown seaweed that grows in coastal fronts around the world. It may aid in protecting the body's cells from damage, may prevent cancer and heart health and aid in weight loss.",
		},
		{
			id: uuid(),
			ingredient: "Manganese Citrate 10%",
			info: "Manganese is a trace mineral that the body needs in small amounts. It may improve bone health, protect the body's cells, help reduce inflammation, play a role in blood sugar regulation, lower the incidence of seizures, play a role in the metabolism of nutrients, reduce PMS symptoms, improve brain function, contribute to thyroid health and aid in wound healing.",
		},
		{
			id: uuid(),
			ingredient: "Anterior Pituitary Substance [Desiccated Beef]",
			info: "",
		},
		{
			id: uuid(),
			ingredient: "Spleen Substance [Desiccated Beef]",
			info: "",
		},
		{
			id: uuid(),
			ingredient: "Thyroid Substance Powder [Beef]",
			info: "",
		},
	],
	quality_tests: [
		[
			{
				id: uuid(),
				title: "Arsenic",
				ppm: "NMT 4ppm",
				icp: "ICP/MS",
				status: "Passed",
			},
			{
				id: uuid(),
				title: "lead",
				ppm: "NMT 4ppm",
				icp: "ICP/MS",
				status: "Passed",
			},
			{
				id: uuid(),
				title: "Calcium",
				ppm: "NMT 2ppm",
				icp: "ICP/MS",
				status: "Passed",
			},
			{
				id: uuid(),
				title: "Zinc",
				ppm: "NMT 3ppm",
				icp: "ICP/MS",
				status: "Passed",
			},
		],
		[
			{
				id: uuid(),
				title: "Yeast and moid",
				ppm: "<1,000,000 cfu/g",
				icp: "FDA BAM 8th",
				status: "Passed",
			},
			{
				id: uuid(),
				title: "E. Coli",
				ppm: "Absent / 10g",
				icp: "USP<2021>",
				status: "Passed",
			},
			{
				id: uuid(),
				title: "Salmonella",
				ppm: "Neg 25/g",
				icp: "MB 217.01 (AOAC Cert #100701) (PCR)",
				status: "Passed",
			},
			{
				id: uuid(),
				title: "APC",
				ppm: "NMT 1,000,000 cfu/g",
				icp: "AOAC 966.23",
				status: "Passed",
			},
		],
	],
};

const pd1404 = {
	map_url: "/demo/assets/images/map_CAGANJTXUT_CNDE.png",
	product_name: "Doctor Rouse Naturals Relieve 60ct",
	batch_no: "0023001767",
	expiry_date: " 10/18/2024",
	product_description:
		"This is the best selling product in the Dr. Rouse Naturals line. It works great to relieve discomfort quickly and safely.. ",
	ingredients: [
		{
			id: uuid(),
			name: "Glucosamine Sulphate/ Potassium 2KCL",
			received: "08/13/2021",
			lot: ["ID#7578-280721", "ID#6691-200621"],
			origin: "Demark",
			code: "DE",
		},
		{
			id: uuid(),
			name: "Maltodextrin M100",
			received: "10/17/2021",
			lot: ["ID# 9472-111021", "ID#7269-280621"],
			origin: "Nebraska, USA",
			code: "US",
		},
		{
			id: uuid(),
			name: "Boswella Serrata Extract 65% [Boswellia Serrata Gum]",
			received: "08/05/2021",
			lot: ["ID#7176-240621"],
			origin: "Thailand",
			code: "TH",
		},
		{
			id: uuid(),
			name: "Magnesium Stearate [Vegetable Grade]",
			received: "11/17/2021",
			lot: ["ID#7214-250621"],
			origin: "Argentina",
			code: "AR",
		},
		{
			id: uuid(),
			name: "Curcumin C3 Complex [Curcuma Longa Rhizomes]",
			received: "09/23/2021",
			lot: ["ID# 9455-111021", "ID#6450-130421"],
			origin: "Kansas, USA",
			code: "US",
		},
		{
			id: uuid(),
			name: "Bioperine (Black Pepper Fruit Extract 50:1)[Piper Nigrum]",
			received: "10/08/2021",
			lot: ["ID#6668-190621"],
			origin: "Texas, USA",
			code: "US",
		},
		{
			id: uuid(),
			name: "175cc White HDPE Bottle [38/400]",
			received: "09/06/2021",
			lot: ["ID#7494-260721"],
			origin: "China",
			code: "CH",
		},
		{
			id: uuid(),
			name: "38mm White, Ribbed Side, Smooth Top Pressure Sensitive Cap 38/400",
			received: "11/03/2021",
			lot: ["ID#7531-260721"],
			origin: "China",
			code: "CH",
		},
		{
			id: uuid(),
			name: "66.0 x 020mm Printed Shrink Band [38mm]",
			received: "11/01/2021",
			lot: ["ID# 10109-101121"],
			origin: "China",
			code: "CH",
		},
	],
	product_info: [
		{
			id: uuid(),
			info: "Manufacturer",
			details: "Nutrafill",
		},
		{
			id: uuid(),
			info: "Website",
			details: "http://www.themedicineman.com/dr-rouse-naturals.html",
		},
		{
			id: uuid(),
			info: "Phone Number",
			details: "+11 2346778",
		},
		{
			id: uuid(),
			info: "Batch Created",
			details: "10/18/2021",
		},
		{
			id: uuid(),
			info: "Quality Tested",
			details: "10/18/2021",
		},
		{
			id: uuid(),
			info: "Verified",
			details: "10/18/2021",
		},
		{
			id: uuid(),
			info: "Recall Notice",
			details: "None",
		},
	],
	filelist: [
		{
			id: uuid(),
			object_url: "/demo/assets/images/pd1404_relieve_1.png",
		},
		{
			id: uuid(),
			object_url: "/demo/assets/images/pd1404_relieve_2.png",
		},
		{
			id: uuid(),
			object_url: "/demo/assets/images/pd1404_relieve_3.png",
		},
	],
	scientific_facts: [
		{
			id: uuid(),
			ingredient: "Glucosamine Sulphate",
			info: "Supports the structure and function of joints.",
		},
		{
			id: uuid(),
			ingredient: "Maltodextrin",
			info: "Improves the texture and mouthfeel of food and beverage" +
				" products. Derived from any starch. In the US, this starch is" +
				" usually corn; in Europe, it is common to use wheat.",
		},
		{
			id: uuid(),
			ingredient: "Boswella Serrata Extract",
			info: "Boswellia serrata contains chemicals that might decrease swelling and increase the body's immune response.",
		},
		{
			id: uuid(),
			ingredient: "Magnesium Stearate",
			info: "Magnesium stearate is an anti-adherent that helps with the" +
				" manufacture of medical tablets, capsules and powders.",
		},
		{
			id: uuid(),
			ingredient: "Curcumin C3 Complex",
			info: "The curcumin-C3 (cur-C3) complex obtained from Curcuma longa rhizome is a combination of three curcuminoids, namely, curcumin, dimethoxycurcumin, and bisdemethoxycurcumin. Cur and curcuminoids have been extensively researched for their wide range of therapeutic properties against inflammatory diseases, diabetes, and cancer.",
		},
		{
			id: uuid(),
			ingredient: "Bioperine",
			info: "BioPerine is a patented extract obtained from black pepper fruits. BioPerine provides a standardized amount of piperine, the bioactive ingredient in black pepper.",
		},
	],
	quality_tests: [
		[
			{
				id: uuid(),
				title: "Arsenic",
				ppm: "NMT 4ppm",
				icp: "ICP/MS",
				status: "Passed",
			},
			{
				id: uuid(),
				title: "lead",
				ppm: "NMT 4ppm",
				icp: "ICP/MS",
				status: "Passed",
			},
			{
				id: uuid(),
				title: "Calcium",
				ppm: "NMT 2ppm",
				icp: "ICP/MS",
				status: "Passed",
			},
			{
				id: uuid(),
				title: "Zinc",
				ppm: "NMT 3ppm",
				icp: "ICP/MS",
				status: "Passed",
			},
		],
		[
			{
				id: uuid(),
				title: "Yeast and moid",
				ppm: "<1,000,000 cfu/g",
				icp: "FDA BAM 8th",
				status: "Passed",
			},
			{
				id: uuid(),
				title: "E. Coli",
				ppm: "Absent / 10g",
				icp: "USP<2021>",
				status: "Passed",
			},
			{
				id: uuid(),
				title: "Salmonella",
				ppm: "Neg 25/g",
				icp: "MB 217.01 (AOAC Cert #100701) (PCR)",
				status: "Passed",
			},
			{
				id: uuid(),
				title: "APC",
				ppm: "NMT 1,000,000 cfu/g",
				icp: "AOAC 966.23",
				status: "Passed",
			},
		],
	],
};

// the SKUs
const demoData = {
	'natural': solution,
	'1404': pd1404,
	'pd1404': pd1404,
};

export default demoData;
