// -- libs
import { Layout } from 'antd';

// -- assets
import '@assets/css/OurHeader.css';
import logo from '@assets/images/truinsights_banner.svg';

// -- setup
const { Header } = Layout;

const OurHeader = () => {
  return(
    <Header>
      <img src={logo} alt="TruInsights" className="logo"/>
    </Header>
  );

}

export default OurHeader;