// -- libs
import React from "react";

// -- assets
import logo from "@assets/images/truinsights_banner.svg";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Home = () => {
  return (
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <a
          className="App-link"
          href="https://truinsights.app"
          target="TruInsights"
          rel="noopener noreferrer"
        >
          Health Products & Food You Can Trust
        </a>
      </header>
  );
}

export default Home;