// -- libs
import React, { useContext } from "react";
import { Row, Col } from "antd";

// -- components
import QcAssetView from "@components/QcAssetView";

// -- context
import BatchContext from "context/BatchContext";
import CertMarkContext from 'context/CertMarkContext';

// -- components
import LoadingSkeleton from "@components/LoadingSkeleton";

const TabQuality = () => {
  const batch = useContext(BatchContext);
  const certMark = useContext(CertMarkContext);
  const { tenant } = certMark;
  const { TISettings } = tenant;

  if (batch.loading || batch.loadingDetails) return <LoadingSkeleton />;

  const rowSettings = {
    gutter: [8,16],
  }

  return(
    <>
      <Row {...rowSettings}>
        <Col span={24}>
          <QcAssetView />
        </Col>
        <Col span={24}>
          <div style={{textAlign:'center'}}>{TISettings?.TI_qualityTabFooter}</div>
        </Col>
      </Row>
      </>
  );
};

export default TabQuality;
