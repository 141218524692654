// -- libs
import React, { useContext } from "react";
import { Empty, Row, Col } from "antd";

// -- context
import CertMarkContext from "context/CertMarkContext";

const TabScience = () => {
  const certMark = useContext(CertMarkContext);
  const { tenant } = certMark;
  const { TISettings } = tenant;

  const rowSettings = {
    gutter: [8, 16],
  };

  return (
    <>
      <Row {...rowSettings}>
        <Col span={24}>
          <Empty description={"Science data is being processed..."} />
        </Col>
        <Col span={24}>
          <div style={{ textAlign: "center" }}>
            {TISettings?.TI_factTabFooter}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TabScience;
