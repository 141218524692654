// -- libs
import React, { useContext } from "react";
import { Table, List, Row, Col } from "antd";

// -- context
import BatchContext from "context/BatchContext";
import CertMarkContext from 'context/CertMarkContext';


const TabIngredients = () => {
  const batch = useContext(BatchContext);
  const certMark = useContext(CertMarkContext);
  const { tenant } = certMark;
  const { TISettings } = tenant;

  const detailColumns = [
    {
      title: 'Received',
      dataIndex: 'dateReceivedStd',
      align: 'left',
      width: 100,
      render: (text, record) =>
        <List
          dataSource={record.lots}
          renderItem={lot => <List.Item>{lot.dateReceivedStd}</List.Item>}
        />
      ,
    },
    {
      title: 'Lot',
      dataIndex: 'unique_id',
      align: 'left',
      width: 250,
      render: (text, record) =>
        <List
          dataSource={record.lots}
          renderItem={lot => <List.Item>{lot.unique_id}</List.Item>}
        />
      ,
    }
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text, record) => {
        return record?.proprietaryNow ?
          'Proprietary Ingredient' :
          record.name;
      }
    },
    {
      title: 'Details',
      dataIndex: 'lots',
      children: detailColumns,
    }
  ];

  const rowSettings = {
    gutter: [8,16],
  }

  return(
    <>
      <Row {...rowSettings}>
        <Col span={24}>
          <Table
            columns={columns}
            rowKey={record => record.id}
            dataSource={batch.groupedConstituents}
            loading={batch.loading || batch.loadingDetails}
            pagination={{
              hideOnSinglePage: true
            }}
          />
        </Col>
        <Col span={24}>
            <div style={{textAlign:'center'}}>{TISettings?.TI_IngredientsTabFooter}</div>
        </Col>
      </Row>
    </>
  );

}

export default TabIngredients;