// -- libs
import React from "react";
import { Row, Col, Card, List } from "antd";
import ReactCountryFlag from "react-country-flag";

// -- TODO: better place for styles
const flagStyle = { width: "5em", height: "5em" };
const flagsListGridConfig = {
  gutter: 16,
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 4,
  xxl: 4,
};

const genIngredientCard = (ingredientRecord) => {
  let { name, lots } = ingredientRecord;
  const ingredientCard = (
    <Col span={24}>
      <Card title={name} bordered={false}>
        <List
          grid={flagsListGridConfig}
          dataSource={lots}
          renderItem={genLotsCardList}
        />
      </Card>
    </Col>
  );
  return ingredientCard;
};

const genLotsCardList = (lotRecord) => {
  const { unique_id, supplier } = lotRecord;
  const { addresses = [] } = supplier || {};
  const mainAddress = addresses.find((item) => item && item.type === "Main");
  const { countryCode, state } = mainAddress || {};
  let locDisp = countryCode === "US" ? `${state}, ${countryCode}` : countryCode;

  if (!locDisp) locDisp = "Unknown";

  const gridItemJsx = (
    <List.Item>
      <Card title={unique_id}>
        {locDisp}{" "}
        <ReactCountryFlag style={flagStyle} svg countryCode={countryCode} />
      </Card>
    </List.Item>
  );
  return gridItemJsx;
};

const IngredientLotOrigins = ({ ingredients }) => {
  const cards = ingredients.map(genIngredientCard);
  return <Row gutter={16}>{cards}</Row>;
};

export default IngredientLotOrigins;
