// -- libs
import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "hooks/useMediaQuery";
import { Carousel, Image } from "antd";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

/**
 * <ImageCarousel .../>
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ImageCarousel = ({ fileList }) => {
	const isDesktop = useMediaQuery("(min-width:992px)");
	if (!fileList) return <></>;
	if (!Array.isArray(fileList)) return <></>;
	if (fileList.length === 0) return <></>;

	const contentStyle = {
		height: "400px",
		color: "#fff",
		lineHeight: "00px",
		textAlign: "center",
		background: "#364d79",
		marginBottom: isDesktop ? "30px" : "20px",
	};

	const carouselItems = fileList.map(file => {
		return (
			<div key={file.id} style={contentStyle} className="content">
				<Image preview={false} src={file.object_url || file.upload_url} height={400} />
			</div>
		);
	});

	return (
		<div>
			<Carousel
				arrows
				prevArrow={<HiChevronLeft />}
				nextArrow={<HiChevronRight />}
				dots={{ className: "carouselDots" }}
				className="centerImages">
				{carouselItems}
			</Carousel>
		</div>
	);
};

// -- Prop Types
ImageCarousel.propTypes = {
	fileList: PropTypes.arrayOf(PropTypes.object),
};

export default ImageCarousel;
