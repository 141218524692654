import { Image } from "antd";
import Origin from "../single/origin";
import { useMediaQuery } from "hooks/useMediaQuery";
function Originsmap({ ingredients, mapUrl }) {
	const isDeskTop = useMediaQuery("(min-width:992px)");
	if (!Array.isArray(ingredients)) return <></>;
	return (
		<div className="py-4 flex-start">
			<div
				style={{
					marginTop: isDeskTop ? "0px" : "50px",
				}}>
				{ingredients?.length > 0 && ingredients.map(ing => <Origin key={ing.id} {...ing} />)}
			</div>
			<div
				style={{
					width: "600px",
				}}>
				<Image src={mapUrl} alt="ing-img" preview={false} />
			</div>
		</div>
	);
}

export default Originsmap;
