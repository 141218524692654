import { Typography, Space } from "antd";
import Flag from "../shared/countryFlag";
function Origin({ name, lot, origin, code }) {
	return (
		<div className="mb-10">
			<div>
				<Flag code={code} title={code} />
				<Typography.Title level={5} className="bold mb-1">
					{name}
				</Typography.Title>
				<div className="flex">
					<div
						className="bold capitalize"
						style={{
							fontSize: "0.92rem",
							marginRight: "10px",
						}}>
						{`Lot : `}
					</div>
					<div
						className="normal capitalize"
						style={{
							fontSize: "0.9rem",
						}}>
						<Space size="large">
							{lot.map(item => (
								<span
									className="bold capitalize"
									style={{
										fontSize: "0.92rem",
									}}>
									{item}
								</span>
							))}
						</Space>
					</div>
				</div>
				<div className="flex">
					<span
						className="bold capitalize"
						style={{
							fontSize: "0.92rem",
							marginRight: "10px",
						}}>
						{`Origin : `}
					</span>
					<span
						className="normal capitalize"
						style={{
							fontSize: "0.92rem",
						}}>
						{origin}
					</span>
				</div>
			</div>
		</div>
	);
}

export default Origin;
