import * as React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";

const appContainer = document.getElementById('root');
const appRoot = createRoot(appContainer);
appRoot.render(
    <BrowserRouter>
      <App />
  </BrowserRouter>,
);
