// -- libs
import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { Row, Col, Tabs, Space } from 'antd';

// -- context
import CertMarkContext from "context/CertMarkContext";
import { BatchProvider } from "context/BatchContext";

// -- utils
import { columnWidths } from "@utils/layout";

// -- Components
import ImageCarousel from "@components/ImageCarousel";
import TabIngredients from "@components/TabIngredients";
import TabQuality from "@components/TabQuality";
import TabScience from "@components/TabScience";
import TabOrigin from "@components/TabOrigin";
import BatchInfo from '@components/BatchInfo';
import TaglineBrand from '@components/TaglineBrand';
import ProductHeader from '@components/ProductHeader';

// -- setup
const { TabPane } = Tabs;


const ProductDisplay = () => {
  const certMark = useContext(CertMarkContext)
  if (!certMark) return (<p>Certification Mark could not be located.</p>);

  const { aliasURL = '', catalog: product = {}} = certMark;

  // Redirect if aliased.
  if (aliasURL) {
    window.location=aliasURL;
    return (
      <Route path="/external" component={(aliasURL) => {window.location = aliasURL; return null;}} />
    );
  }

  let { fileList = [] } = product

  return (
    <BatchProvider>
      <div className="site-layout-content">
        <Row>
          <Col {...columnWidths.half}>
            <ImageCarousel fileList={fileList} />
          </Col>
          <Col {...columnWidths.half}>
            <ProductHeader />
            <Space direction="vertical">
              <TaglineBrand />
              <BatchInfo/>
            </Space>
          </Col>
        </Row>
        <Tabs type="card">
          <TabPane tab="Ingredients" key="ingredients">
            <TabIngredients />
          </TabPane>
          <TabPane tab="Quality Checks" key="quality">
            <TabQuality />
          </TabPane>
          <TabPane tab="Scientific Facts" key="facts">
            <TabScience />
          </TabPane>
          <TabPane tab="Traceability" key="origin">
            <TabOrigin />
          </TabPane>
        </Tabs>
      </div>
    </BatchProvider>
  );
}

export default ProductDisplay;