// -- libs
import React, { useEffect } from "react";

// -- utils
import { useState } from "react";
import axios from "@utils/axios";

const endpoint = '/batch';

const defaultState = {
  details: {
    batch_num: '',
    productionStartDate: '',
    expiration_date: '',
    formula: {},
    ingredients: [],
  },
  groupedConstituents: [],
  loading: false,
  loadingDetails: false,
  list: [],
}

const BatchContext = React.createContext(defaultState);

export const BatchProvider = ({ children }) => {
  const [list, setList] = useState(defaultState.list);
  const [details, setDetails] = useState(defaultState.details);
  const [groupedConstituents, setGroupedConstituents] = useState(defaultState.groupedConstituents);
  const [loading, setLoading] = useState(defaultState.loading);
  const [loadingDetails, setLoadingDetails] = useState(defaultState.loadingDetails);

  useEffect(() => {
    if (!details.ingredients.length) return;
    const ingredients = details.ingredients.filter(constituent => (constituent.type === 'ingredient'));
    const components = details.ingredients.filter(constituent => (['component', 'label'].includes(constituent.type)));
    const other = details.ingredients.filter(constituent => (constituent.type !== 'ingredient' && constituent.type !== 'component' && constituent.type !== 'label'));
    setGroupedConstituents([...ingredients, ...components, ...other]);
  }, [details.batch_num]);

  /**
   * Get a summary list of batches.
   * @param params
   * @returns {Promise<void>}
   */
  const getSummaryList = async (params = {}) => {
    setLoading(true);
    const response = await axios.get(endpoint + '/summary/', { params });
    if (!response.data) return;
    setList(response.data.records)
    setLoading(false);
  }

  /**
   * Get a batch details.
   * @param params
   * @returns {Promise<void>}
   */
  const getDetails = async (params = {}) => {
    setLoadingDetails(true);
    const response = await axios.get(endpoint + '/details/' + params.id + '/?verbosity=50');
    if (!response.data) return;
    setDetails(response.data.records[0]);
    setLoadingDetails(false);
  }

  const state = {
    details,
    loading,
    loadingDetails,
    list,
    getDetails,
    getSummaryList,
    groupedConstituents,
  };

  return (
    <BatchContext.Provider value={state}>
      {children}
    </BatchContext.Provider>
  )
};

export default BatchContext;