/**
 * Ant Design Layouts
 */
export const columnWidths = {
  // mobile: full , tablet 1/4, desk 1/4
  typical: { xs: 24, sm: 24, md: 6, lg: 6, xl: 6 },

  // mobile: full , tablet 1/2, desk 1/2
  half: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },

  // mobile: full , tablet full, desk full
  full: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },

  // mobile: 1/4 , tablet 1/4, desk 1/4
  quarter: { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 },

  // mobile: 1/2 , tablet 1/3, desk 1/3
  third: { xs: 12, sm: 12, md: 8, lg: 8, xl: 8 },

  // mobile: 1/2 , tablet 1/3, desk 1/3
  two_thirds: { xs: 12, sm: 12, md: 16, lg: 16, xl: 16 },
};