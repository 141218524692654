// -- libs
import React, { useContext } from "react";
import { Descriptions } from 'antd';

// -- context
import BatchContext from "context/BatchContext";
import CertMarkContext from 'context/CertMarkContext';

// -- components
import LoadingSpin from "@components/LoadingSpin";
import { BatchSelector } from '@components/BatchSelector';

/**
 * <BatchInfo .../>
 * @returns {JSX.Element}
 * @constructor
 */
const BatchInfo = () => {
  const batch = useContext(BatchContext);
  const certMark = useContext(CertMarkContext)
  const { catalog: product = {} } = certMark;

  const loadingIndicator =
    (batch.loading || batch.loadingDetails) ?
      (<LoadingSpin loading/>) :
      null;

  const expiration = new Date(batch.details.expiration_date);
  const expDisplay = expiration ? expiration.toLocaleDateString() : null;

  return (
    <>
      <Descriptions  bordered column={2}>
        <Descriptions.Item label="Batch"><BatchSelector sku={product.code}/></Descriptions.Item>
        <Descriptions.Item label="Expiration">{loadingIndicator || expDisplay}</Descriptions.Item>
        <Descriptions.Item label="SKU">{product.code}</Descriptions.Item>
        <Descriptions.Item label="Mfg Date">{loadingIndicator || batch.details.productionStartDate}</Descriptions.Item>
      </Descriptions>
    </>
);
}
export default BatchInfo;