// -- libs
import * as React from "react";
import { Routes, Route } from "react-router-dom";
import 'antd/dist/antd.min.css'

// -- pages
import Home from "@pages/Home";
import Info from "@pages/Info";
import Demo from "@pages/Demo";

//
// -- assets
import "@assets/css/App.css";

/**
 *
 * @constructor
 * 
 */
function App() {
	return (
		<div className="App">
			<Routes>
				<Route path="home" element={<Home />} />
				<Route path="info/:code" element={<Info />} />
				<Route path="sku/:sku" element={<Demo />} />
			</Routes>
		</div>
	);
}

export default App;
