import React from "react";
import ReactCountryFlag from "react-country-flag";

const Flag = ({ code, title }) => {
	return (
		<div>
			<ReactCountryFlag
				countryCode={code}
				svg
				style={{
					width: "3em",
					height: "3em",
				}}
				title={title}
			/>
		</div>
	);
};
export default Flag;
