import { Tabs } from "antd";
import { useMediaQuery } from "hooks/useMediaQuery";
import Ingridienttable from "./ingridienttable";
import Facts from "./facts";
import Tests from "./tests";
import Originsmap from "./originsmap";

function ProductTabs({ product }) {
	const isDeskTop = useMediaQuery("(min-width:992px)");
	const { TabPane } = Tabs;
	const { ingredients, scientific_facts: facts, quality_tests: tests, map_url } = product;
	return (
		<div
			style={{
				marginTop: isDeskTop ? "60px" : "30px",
			}}>
			<Tabs type="card">
				<TabPane tab="Ingredients" key="ingredients">
					<Ingridienttable size={"small"} data={ingredients} />
				</TabPane>
				<TabPane tab="Quality Checks" key="quality">
					<Tests Tests={tests} />
				</TabPane>
				<TabPane tab="Scientific Facts" key="facts">
					<Facts facts={facts} />
				</TabPane>
				<TabPane tab="True Origins" key="origin">
					<Originsmap ingredients={ingredients} mapUrl={map_url} />
				</TabPane>
			</Tabs>
		</div>
	);
}

export default ProductTabs;
